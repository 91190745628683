html {
  font-size: $font-size-base;
}

body {
  font-family: $font-normal;
  line-height: 1.5;
  color: $color-1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizelegibility;
}

/*
************* TITRE
* ===================================
*/

// ------------ Custom

.title__h1,
h1 {
  display: block;
  font-family: $font-titre;
  line-height: 1.5;
  font-size: rem(70);
  color: $color-3;
  margin: 2rem 0;
}
.title__intro {
  position: relative;
  font-size: rem(30);
  text-transform: uppercase;
  font-family: $font-titre;
  line-height: 1;
  margin-bottom: rem(25);
  .title {
    position: relative;
    z-index: 10;
    background-color: white;
    padding-right: 1rem;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 100%;
    height: 2px;
    background-color: $color-2;
  }
}
h2 {
  font-family: $font-titre;
  font-size: rem(30);
}
// ------------ EO H2
h3 {
  font-size: rem(24);
  font-family: $font-titre;
  font-weight: normal;
  line-height: 1.5;
}

h4 {
  line-height: 1.5;
}

h5 {
  line-height: 1.5;
}

h6 {
  line-height: 1.5;
}

/*
************* TEXTE
* ===================================
*/

p {
  font-size: rem(18);
  font-weight: 400;
  line-height: 1.7;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    //color: $color-1;
    //transition: color 200ms ease-in-out;
  }
}

address {
  font-style: normal;
}

// ===================================== RESPONSIVE

@include breakpoint(1680) {
  .title__h1 {
    font-size: rem(60);
  }
}

@include breakpoint(1440) {
  .title__h1 {
    font-size: rem(50);
  }
}

@include breakpoint(1366) {
  .title__h1 {
    font-size: rem(45);
  }
}

@include breakpoint(1180) {
  .title__h1 {
    font-size: rem(40);
  }
  .title__intro {
    margin-bottom: 1rem;
  }
}
@include breakpoint(1024) {
}
@include breakpoint(450) {
  .title__h1 {
    font-size: rem(30);
  }
  .title__intro {
    font-size: rem(23);
  }
}
