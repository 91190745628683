.footer {
  position: relative;
  background-color: white;
  margin-top: 10rem;
  border-top: 2px solid $color-1;
}

.footer__container {
  display: grid;
  grid-template-columns: minmax(200px, 300px) minmax(200px, 300px) minmax(
      400px,
      1fr
    );
  grid-auto-flow: dense;
  gap: 50px;
  align-items: baseline;
  padding: 2rem;
}

// --------------------------------------------------------- items
.footer__item {
  i {
    color: $color-1;
  }
}

// --------------------------------------------------------- title
.footer__title,
.footer__item--menu ul::before {
  display: block;
  font-size: rem(22);
  color: $color-3;
  margin-bottom: 1rem;
  font-family: $font-titre;
}

// --------------------------------------------------------- logo
.footer__item--logo {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

// --------------------------------------------------------- navigation
.footer__item--menu {
  ul::before {
    content: "Navigation";
  }
  //------------------------------------------------------ li
  li {
    // margin-left: rem(10);
  }

  //------------------------------------------------------ a
  a {
    display: block;
    font-size: rem(16);
    font-weight: 500;
    transition: all 200ms;
    padding: 2px 0;
  }

  li.active a {
    color: $color-1;
  }
  //------------------------------------------------------ hover
  a:hover {
    color: $color-1;
  }
}

// --------------------------------------------------------- contact
.footer__item--contact {
  li {
    margin: rem(10) 0;
  }
}

.contact__link {
  display: flex;
  align-items: center;

  i {
    margin-right: rem(8);
    font-size: rem(26);
    text-align: center;
    width: 30px;
    line-height: 30px;
    color: $color-2;
  }

  address,
  span {
    font-size: rem(16);
  }
}

// --------------------------------------------------------- partenaires

.footer__item--partenaires {
  .items {
    display: flex;
    flex-wrap: wrap;
  }
  img {
    // display: inline-block;
  }
}

// --------------------------------------------------------- horaires

.footer__item--horaires {
  .horaires__day {
  }
}

// --------------------------------------------------------- Mentions
.footer__mentions {
  background-color: $color-1;
  border-left: 1px solid $color-3;
  color: white;
  padding: 10px 0 10px 20px;
  font-family: $font-titre;
  p {
    font-size: rem(16);
  }
  strong {
    // color: $color-3;
  }
}

// .footer__title::before {
//   display: inline-flex;
//   font-family: var(--font-awe);
//   content: "\f004";
// }

// ----------------------------------- Responsive

@include breakpoint(1250) {
  .footer__container {
    gap: 10px;
  }
}

@include breakpoint(880) {
  .footer__container {
    grid-template-columns: 1fr 1fr;
  }
  .footer__item--partenaires {
    grid-column: 1 / -1;
    // text-align: center;
    .items {
      // justify-content: center;
    }
  }
}

@include breakpoint(550) {
  .footer__container {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .footer__item--partenaires {
    .items {
      justify-content: center;
    }
  }
  .contact__link {
    justify-content: center;
  }
}
