.contact__wrapper {
  .items {
    display: grid;
    grid-template-columns: minmax(300px, 400px) minmax(400px, 1fr);
    gap: 40px;
  }
}

// -------------------------------------------- SLIDE
.contact__slide {
  position: relative;

  .swiper-container {
    height: 600px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: $color-2;
    transform: translateY(-50%);

    &::after {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
  .swiper-button-prev::after {
    padding-right: 6px;
  }
  .swiper-button-next::after {
    padding-left: 2px;
  }
}

// -------------------------------------------- FOX CONTACT

.fox-container {
  text-align: center;
  background: white;
  overflow: hidden;
}

// ------------------------ suppression des asterix
.required::after,
.asterisk::after {
  content: none !important;
}

// ------------------------ suppression des floats
.fox-item .controls {
  float: none !important;
}
// ------------------------ btn envoyer
.fox-item.fox-item-submit-fields .controls {
  width: 100% !important;

  .btn {
    margin-top: 1rem;
    width: 100%;
    padding: rem(10) 0;
  }
}

// -------------------------------------------- MAP
#map {
  width: 100%;
  height: 500px;
}

/* ======================================================================  RESPONSIVE ============================= */

@include breakpoint(800) {
  .contact__wrapper .items {
    grid-template-columns: minmax(300px, 1fr);
  }
  .contact__slide .swiper-container {
    height: 400px;
  }
}
