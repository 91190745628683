html {
  // scroll-behavior: smooth; / Remplacé par le smooth JS compatible IOS
  // height: 4000px;
}

body {
  word-break: break-word;
  background-color: $color-light;
  // padding-bottom: 10rem;
}

#wrapper {
  position: relative;
  // width: 100%;
  margin: auto;
  min-height: 100vh;
  // overflow: hidden;
}

.main {
  margin-left: 330px;
  padding: 0 rem(54);
}

.footer {
  margin-left: 330px;
}

.container {
  // margin: 0 rem(54);
}

.section {
  padding: 0 0 rem(50) 0;
}

@include breakpoint(1700) {
  .main {
    margin-left: 275px;
    padding: 0 rem(32);
  }
  .footer {
    margin-left: 275px;
  }
  .container {
    // margin: 0 rem(45);
  }
}

@include breakpoint(1440) {
  .main {
    margin-left: 250px;
    padding: 0 rem(28);
  }
  .footer {
    margin-left: 250px;
  }
  .containter {
    // margin: 0 rem(32);
  }
}

@include breakpoint(1180) {
  .main {
    margin-left: 0;
    padding: 0 rem(26);
  }
  .footer {
    margin-left: 0;
  }
  .containter {
    // margin: 0 rem(26);
  }
}

@include breakpoint(550) {
  .container {
    margin: 0;
    padding: 0;
  }
  .main {
    padding: 0 rem(8);
  }
}
