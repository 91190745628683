//--------------------------------------------------------------------------- MOVIES

.movies {
  .title__intro {
    margin-bottom: 2.5rem;
  }
}

//--------------------------------------------------------------------------- HEADER
.home-header {
  display: flex;
}

@include breakpoint(1250) {
  .home-header .date__buttons {
    margin: 0 1rem;
  }
}

@include breakpoint(980) {
  .home-header {
    display: block;
    margin: 1rem 0 2rem 0;
  }
  .home-header .date__buttons {
    margin: 0 1rem 0 0;
  }
}

//--------------------------------------------------------------------------- EVENT

.event__all {
  display: grid;
  grid-template-columns: minmax(175px, 275px) 1fr;
  grid-auto-flow: dense;
  gap: 50px;
  margin-bottom: 3rem;
}

.event__all .cards--film {
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
}

.event__animspec {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
  grid-auto-flow: dense;
  gap: 25px;
}

.event__movie {
  margin-bottom: 4rem;
}

.swiper-container {
  overflow: hidden;
  height: 100%;
}
.swiper__animations {
  width: 100%;
  // height: 300px;
}
.swiper__prevnext {
  display: flex;
  justify-content: space-between;
  height: 37px;
  align-items: center;

  .swiper-button-next,
  .swiper-button-prev {
    position: relative;
    width: auto;
    right: initial;
    left: initial;
    top: initial;
    margin-top: initial;
    height: initial;
    color: $color-2;
    &::after {
      content: "";
    }
    &:hover {
      color: $color-1;
    }
    &:hover i {
      background-color: $color-1;
    }
    i {
      width: 28px;
      height: 28px;
      line-height: 28px;
      color: white;
      text-align: center;
      font-size: rem(26);
      background-color: $color-2;
    }
    .fa-angle-left {
      margin-right: rem(5);
    }
    .fa-angle-right {
      margin-left: rem(5);
    }
  }
}

.programmes--movies {
  margin-top: 4rem;
}

@include breakpoint(1700) {
  .event__all {
    grid-template-columns: 250px 1fr;
    gap: 40px;
  }
}

@include breakpoint(1600) {
  .event__all {
    grid-template-columns: 225px 1fr;
    gap: 35px;
  }
}

@include breakpoint(1280) {
  .event__all {
    grid-template-columns: 200px 1fr;
    gap: 30px;
  }
  .event__animspec {
    grid-template-columns: minmax(300px, 1fr);
    gap: 0;
  }
}

@include breakpoint(980) {
}

@include breakpoint(780) {
}

@include breakpoint(650) {
  .event__all {
    grid-template-columns: 1fr;
  }
  .event__animspec {
    grid-template-columns: minmax(260px, 1fr);
  }
}
