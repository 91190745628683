.popup__message {
  position: fixed;
  z-index: 998;
  bottom: 1rem;
  left: calc(330px + 1rem);
  max-width: 350px;
  width: 90%;
  background-color: $color-3;
  color: white;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.35);
  border: 3px solid $color-2;

  .popup__close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: $color-2;
    font-size: rem(30);
    cursor: pointer;
  }

  h2 {
    font-size: rem(24);
  }

  p {
    font-size: rem(16);
  }
}

@include breakpoint(1700) {
  .popup__message {
    left: calc(275px + 1rem);
  }
}

@include breakpoint(1440) {
  .popup__message {
    left: calc(250px + 1rem);
  }
}

@include breakpoint(1180) {
  .popup__message {
    left: 1rem;
  }
}

/* -------------------------- POPUP ANIMATIONS SPECTACLES */

.popup_introtext {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: calc(50% + 175px);
  width: 90%;
  max-width: 980px;
  background: white;
  max-height: 90vh;
  overflow-y: auto;
  color: #0f2027;
  padding: 2rem 1rem 1rem 1rem;
  transform: translate(-50%, -50%) scale(1, 0);
  box-shadow: 10px 20px 41px rgba(0, 0, 0, 0.26);
  transition: all 0.15s;
}

.popup_introtext-active {
  transform: translate(-50%, -50%) scale(1, 1);
}

.close__introtext {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 1700px) {
  .popup_introtext {
    left: calc(50% + 138px);
  }
}
@media screen and (max-width: 1440px) {
  .popup_introtext {
    left: calc(50% + 125px);
  }
}

@media screen and (max-width: 1180px) {
  .popup_introtext {
    left: 50%;
  }
}
