.slide__ecran {
  width: 1080px;
  height: 1920px;
  background-color: #071317;
  color: white;
  text-align: center;

  span {
    display: block;
    margin: 8px 0;
    font-size: 5rem;
    font-family: $font-titre;
    line-height: 1;
  }

  //   ------------------------ ecran 1

  .swiper-slide--e1 {
    img {
      width: 100%;
    }
    .slide__desc {
      margin-top: 3rem;
    }
  }

  //   ------------------------ ecran 2
  .swiper-slide--e2 {
    img {
      max-height: 550px;
    }
  }

  .slide__ecran__title {
    display: block;
    margin: 1rem 0 2rem 0;
    font-size: 6rem;
  }

  .item {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-bottom: 2rem;
  }
  .right {
    text-align: left;
  }
}
