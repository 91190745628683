.tarifs {
}

.tarifs__content {
  display: grid;
  grid-template-columns: minmax(450px, 0.67fr) minmax(500px, 1fr);
  gap: 50px;
}

// -------------------------------------------- LISTE PRIX

.tarifs__list {
  .card {
    position: relative;
    padding: rem(16) rem(24) rem(16) rem(16);
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.16);
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // transition: all 0.15s;
    &::after {
      content: "";
      position: absolute;
      z-index: 10;
      right: 0;
      top: 0;
      width: 8px;
      height: 100%;
      background-color: $color-3;
    }
  }

  .card__title {
    font-family: $font-titre;
    font-size: rem(30);
  }

  .card__price {
    font-family: $font-titre;
    font-size: rem(50);
    color: $color-2;
  }

  .card__infos {
    position: absolute;
    bottom: rem(4);
    font-size: rem(14);
    font-weight: 500;
    width: calc(100% - 30px);
  }
}

// -------------------------------------------- SLIDE
.tarifs__slide {
  position: relative;

  .swiper-container {
    height: 400px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: $color-2;
    transform: translateY(-50%);

    &::after {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
  .swiper-button-prev::after {
    padding-right: 6px;
  }
  .swiper-button-next::after {
    padding-left: 2px;
  }
}

// -------------------------------------------- PAIEMENTS

.tarifs__choice {
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    grid-auto-flow: dense;
    grid-gap: 10px;
  }
  .card {
    text-align: center;
    background-color: white;
    display: grid;
    box-shadow: 5px 10px 21px rgba($color: #000000, $alpha: 0.16);
    padding: rem(4) rem(4);
  }

  img {
    margin: auto;
  }
  .card__title {
    font-weight: 500;
    font-size: rem(16);
  }
}

@include breakpoint(1700) {
  .tarifs__list .card {
    padding: 16px 16px 16px 16px;
  }
  .tarifs__list .card__title {
    font-size: rem(25);
  }
  .tarifs__list .card__price {
    font-size: rem(35);
  }
}

@include breakpoint(1600) {
  .tarifs__content {
    grid-template-columns: minmax(390px, 0.67fr) minmax(450px, 1fr);
    gap: 25px;
  }
  .tarifs__list .card__title {
    font-size: rem(22);
  }
  .tarifs__list .card__price {
    font-size: rem(32);
  }
  .tarifs__list .card__infos {
    font-size: rem(12);
  }
}

@include breakpoint(950) {
  .tarifs__content {
    grid-template-columns: minmax(300px, 1fr);
  }
}

@include breakpoint(440) {
  .tarifs__list .card {
    padding: 0 1rem;
    height: 80px;
  }
  .tarifs__list .card__infos {
    line-height: 1;
    font-size: rem(11);
  }
  .tarifs__utils p {
    font-size: rem(16);
  }
  .tarifs__slide .swiper-container {
    height: 300px;
  }
}
