// --------------------------------------------------------------------------- ANIMATIONS / SPECTACLES FILMES

.cards--animations-spectacles {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.card__introduction {
}

// --------------------------------------------------------------------------- VR

.cards--vr {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  .card {
    position: relative;
    display: grid;
    grid-template-columns: minmax(175px, 240px) minmax(250px, 1fr);
    grid-gap: 20px;
    align-items: center;
    // color: white;
    // height: 370px;
    padding: 1rem;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.16);
  }

  .card__vr-360 {
    position: absolute;
    display: flex;
    align-items: center;
    right: 5px;
    top: 5px;
    text-transform: uppercase;
    font-family: $font-titre;
    font-size: rem(18);
    svg {
      margin-right: 8px;
    }
  }

  // ------------------------ infos
  .card__infos {
    margin-bottom: 1rem;
  }

  .card__movie {
    font-family: $font-titre;
    font-size: rem(20);
    margin-bottom: 1rem;
  }

  .card__lang {
    font-size: rem(16);
  }
  .card__infos__title {
    font-family: $font-titre;
  }
  .card__infos__result {
    font-size: rem(14);
  }

  img {
  }

  h2 {
    font-size: rem(22);
    margin-bottom: 1rem;
    &::after {
      content: "";
      display: block;
      width: 75px;
      height: 1px;
      background-color: $color-2;
    }
  }

  .card__description {
    font-size: rem(14);
    line-height: 1.6;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .btn {
    margin-top: 1rem;
  }
}

// --------------------------------------------------------------------------- FILMS

.cards--film {
  display: grid;
  //   grid-template-columns: repeat(auto-fill, minmax(175px 1fr))
  grid-template-columns: repeat(5, minmax(175px, 1fr));
  grid-auto-flow: dense;
  gap: 40px;
  .card {
    .card__hour {
      display: block;
      color: $color-1;
      text-align: center;
      font-family: $font-titre;
      font-size: rem(25);
    }
    .card__body {
      position: relative;

      .favorite {
        position: absolute;
        z-index: 10;
        right: -10px;
        top: -19px;
        width: 38px;
        height: 38px;
        line-height: 40px;
        text-align: center;
        color: white;
        background-color: $color-3;
        border-radius: 50%;
        font-size: 1.2rem;
        box-shadow: 5px 10px 21px rgba($color: #000000, $alpha: 0.36);

        i {
          animation: pulse 2s infinite;
        }
      }

      @keyframes pulse {
        10% {
          transform: scale(1.1);
        }
        15% {
          transform: scale(1);
        }
        25% {
          transform: scale(1.2);
        }
        50% {
          transform: scale(1);
        }
      }

      img {
        display: block;
        box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.25);
        width: 100%;
        //   height: 80%;
        //   object-fit: cover;
        //   object-position: center;
      }

      // ---------------------------------- Duréé + Langue
      .card__infos {
        position: absolute;
        z-index: 10;
        bottom: -5px;
        right: 5px;
      }
      .card__duration,
      .card__lang {
        padding: 2px 6px;
        font-family: $font-titre;
        font-size: rem(17);
        border-radius: 3px;
        box-shadow: 3px 6px 12px rgba($color: #000000, $alpha: 0.2);
      }
      .card__duration {
        background-color: $color-3;
        color: white;
      }
      .card__lang {
        background-color: white;
        color: $color-2;
      }
    }

    .card__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      .card__title {
        display: block;
        font-size: rem(16);
        font-weight: bold;
        text-transform: uppercase;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .card__author {
        display: block;
        font-size: rem(14);
        color: $color-3;
      }
      .card__buttons {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
      .card__play,
      .card__ticket {
        width: 28px;
        min-width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: $color-2;
        border: 2px solid transparent;
        border-radius: 50%;
        padding-left: 2px;
        transition: all 0.15s;
        font-size: 0.9rem;
        &:hover {
          border-color: $color-2;
          color: $color-2;
          background-color: transparent;
        }
      }
    }
  }
}

@include breakpoint(1440) {
  .cards--film {
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    gap: 25px;
  }
  .cards--film .card .card__body .favorite {
    width: 32px;
    height: 32px;
    line-height: 34px;
    font-size: rem(15);
    top: -12px;
    right: -4px;
  }
  .cards--film .card .card__footer .card__title {
    font-size: rem(15);
  }
  .cards--film .card .card__footer .card__author {
    font-size: rem(12);
  }
}

@include breakpoint(440) {
  .cards--film {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 15px;
  }
  .cards--film .card .card__body .favorite {
    width: 26px;
    height: 26px;
    line-height: 28px;
    font-size: rem(12);
    top: -12px;
  }
}

// --------------------------------------------------------------------------- ANIMATIONS / SPECTACLES

.card--animation,
.card--spectacles {
  position: relative;
  display: grid;
  grid-template-columns: minmax(175px, 240px) minmax(250px, 1fr);
  grid-gap: 15px;
  align-items: center;
  color: white;
  // height: 367px;
  padding: 0 1rem;
  overflow: hidden;

  .card__infos {
    position: absolute;
    top: rem(20);
    right: 5px;
    // display: flex;
    // flex-direction: column;
    // text-align: right;
  }

  .card__movie {
    background-color: white;
    color: $color-1;
    padding: 2px 8px;
    border-radius: 3px;
    font-family: $font-titre;
    font-size: rem(18);
    border: 2px solid transparent;
  }

  .card__category {
    // background-color: white;
    display: inline-flex;
    margin-top: rem(6);
    color: white;
    padding: 2px 8px;
    border-radius: 3px;
    font-family: $font-titre;
    font-size: rem(18);
    border: 2px solid white;
  }

  img {
  }

  h2 {
    font-size: rem(22);
    margin-bottom: 1rem;
    &::after {
      content: "";
      display: block;
      width: 75px;
      height: 1px;
      background-color: $color-2;
    }
  }

  .card__description {
    font-size: rem(14);
    line-height: 1.6;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .btn {
    margin-top: 1rem;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid $color-2;
      background-color: transparent;
    }
  }
}

.card--animation {
  background-color: $color-1;
}

.card--spectacles {
  background-color: $color-3;
}

//--------------------------------- RESPONSIVE VR / ANIMATIONS / SPECTACLES

@include breakpoint(1280) {
  .cards--vr,
  .cards--animations-spectacles {
    grid-template-columns: minmax(300px, 1fr);
  }

  .card--animation,
  .card--spectacles,
  .cards--vr .card {
    height: auto;
    padding: 1rem;
    grid-template-columns: 230px 1fr;
  }
}

@include breakpoint(980) {
  .card--animation,
  .card--spectacles,
  .cards--vr .card {
    grid-template-columns: 175px 1fr;
  }
}

@include breakpoint(780) {
  .card--animation,
  .card--spectacles,
  .cards--vr .card {
    grid-template-columns: 125px 1fr;
  }
  .card--animation .card__infos,
  .card--spectacles .card__infos {
    position: relative;
    top: initial;
    right: initial;
    margin-bottom: rem(8);
  }
}

@include breakpoint(550) {
  .card--animation h2,
  .card--spectacles h2 {
    font-size: rem(18);
  }
  .cards--film .card .card__footer .card__title {
    font-size: 0.8rem;
  }
}

@include breakpoint(440) {
  .card--animation,
  .card--spectacles,
  .cards--vr .card {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .card--animation h2::after,
  .card--spectacles h2::after {
    margin: auto;
  }
  .card--animation img,
  .card--spectacles img,
  .cards--vr img {
    margin: auto;
  }

  .card--animation .card__movie,
  .card--spectacles .card__movie {
    display: block;
    margin-bottom: rem(8);
  }

  .cards--vr .card {
    padding: 2.5rem 1rem 1rem 1rem;
    grid-gap: 0;
  }
}
