/*
************* BTN PRIMARY
* ===================================
*/

.btn {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  touch-action: manipulation;
  border: 3px solid transparent;
  color: white;
  height: initial;
  background-color: $color-2;
  font-family: $font-titre;
  // @include shadow-1;
  font-size: rem(20);
  border-radius: 2px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px 12px;

  // transition: background 200ms, color 200ms;
  // margin-top: 2rem;
  i,
  svg {
    margin-right: rem(8);
  }

  &:hover {
    background-color: rgba($color: $color-1, $alpha: 1);
    transition: all 200ms;
    // border: 3px solid transparent;
  }

  &:active,
  &:focus,
  &.active {
    background-color: $color-2;
    color: white;
  }
}

button {
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  touch-action: manipulation;
  font-family: $font-titre;
}

.btn--2 {
  background-color: $color-1;
  &:hover {
    background-color: rgba($color: $color-2, $alpha: 1);
    transition: all 200ms;
    // border: 3px solid transparent;
  }

  &:active,
  &:focus {
    background-color: $color-2;
    color: white;
  }
}

/*
************* BTN SECONDARY
* ===================================
*/

.btn--outlined {
  background-color: transparent;
  color: $color-2;
  border: 2px solid $color-2;

  &:hover {
    background-color: rgba($color: $color-2, $alpha: 1);
    transition: all 200ms;
    color: white;
    // border: 3px solid transparent;
  }

  &:active,
  &:focus,
  &.active {
    background-color: $color-2;
    color: white;
  }
}

/*
************* DISABLED
* ===================================
*/
.btn[disabled] {
  cursor: default;
  opacity: 0.1;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-1;
    color: $color-light;
  }
}

/*
************* BTN TOP
* ===================================
*/

.btn__top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  transform: translateY(150px);
  z-index: 1000;

  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
  cursor: pointer;
  .btn__top__link {
    // background-color: $gray-6;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $color-2;
    color: white;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.55);
    // border: 3px solid $color-light;
    transition: all 0.2s;

    i {
      font-size: 2rem;
    }
  }
}
.btn__top--active {
  transform: translateY(0);
  opacity: 1;
}

@include breakpoint(650) {
  .btn {
    padding: 4px 8px;
    font-size: rem(16);

    i,
    svg {
      margin-right: rem(5);
    }
  }
  .btn__top .btn__top__link {
    width: 50px;
    height: 50px;
  }
  .btn__top .btn__top__link i {
    font-size: 1.5rem;
  }
}
