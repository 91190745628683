/* Couleur client */
.mentions-politique a,
.fox_link,
.mentions-politique h2,
#tarteaucitronDisclaimerAlert {
  color: $color-1 !important;
  text-decoration: none !important;
}
.titre-nb,
#tarteaucitronPersonalize,
#tarteaucitronPercentage {
  background-color: $color-1 !important;
}
/* -------------- */

.mentions-politique {
  margin: 50px 0;
  padding: 0 50px;
}

.mentions-politique h2,
.mentions-politique h3,
.mentions-politique h4 {
  display: block;
  font-family: inherit;
  font-weight: bold;
  letter-spacing: 0;
}

.mentions-politique h2 {
  font-size: 1.2rem;
  margin: 40px 0 15px 0;
  padding: 10px 0 10px 25px;
  background-color: #f1f1f1;
  border-bottom: 3px solid #ccc;
}

.mentions-politique h3 {
  font-size: 1.1rem;
  padding: 5px 0 5px 35px;
  background-color: #f9f9f9;
  border-bottom: 2px solid #eaeaea;
  margin: 25px 0 10px 0;
}

.mentions-politique h4 {
  font-size: 1rem;
  padding: 5px 0 5px 55px;
  background-color: #f1f1f1;
  margin: 25px 0 10px 0;
}

.mentions-politique p {
  padding: 7px 20px;
  text-align: justify;
}
.mentions-politique .no_justify {
  text-align: left;
}

.mentions-politique ul {
  margin: 20px 0 20px 50px;
}
.mentions-politique li {
  margin: 5px 0;
  list-style-type: circle;
}
.mentions-politique a {
  text-decoration: none;
  border-bottom: 1px dotted #797979;
  overflow-wrap: break-word;
}
.mentions-politique a:hover {
  border-bottom: 1px dotted #191818;
  color: #191818;
}
.titre-nb,
.sous_titre-nb,
.sous_s_titre-nb {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  text-align: center;
  margin-right: 7px;
  color: #fff;
}

.titre-nb {
  min-width: 45px;
  line-height: 45px;
}

.sous_titre-nb {
  background-color: #4c4c4c;
  min-width: 40px;
  line-height: 40px;
}
.sous_s_titre-nb {
  background-color: #9c9c9c;
  min-width: 25px;
  line-height: 25px;
}

@media screen and (max-width: 600px) {
  .mentions-politique {
    padding: 0 10px;
  }
  .mentions-politique h2 {
    font-size: 0.8rem;
    padding: 10px 0 10px 15px;
  }
  .mentions-politique h3 {
    font-size: 1rem;
    padding: 5px 0 5px 20px;
  }

  .mentions-politique h4 {
    font-size: 0.9rem;
    padding: 5px 0 5px 25px;
  }
  .mentions-politique p {
    padding: 7px 10px;
  }
  .titre-nb,
  .sous_titre-nb,
  .sous_s_titre-nb {
    font-weight: bold;
    background-color: transparent;
    color: #191818;
    min-width: auto;
    line-height: initial;
  }
}
