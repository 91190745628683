/* -------------------------- GERER MON ABONNEMENT */
.gixmail.submanage {
  padding: 7rem 0;
  max-width: 500px;
  text-align: center;
  margin: auto;
}

.gixmail.submanage label {
  margin-left: 5px;
  text-transform: capitalize;
  font-weight: 400;
}
