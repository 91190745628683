.programmes {
  .calendar__header,
  &.programmes--movies .calendar__link,
  .calendar__border {
    display: grid;
    grid-template-columns: minmax(280px, 1fr) repeat(7, minmax(85px, 140px));
  }

  // -------------------------------------------------- VR

  &.programmes--vr {
    .calendar__header,
    .item,
    .calendar__border {
      display: grid;
      grid-template-columns: repeat(7, minmax(85px, 1fr));
    }
    .none,
    .calendar__border .border__item:first-child {
      display: none;
    }
    .btn {
      margin: 2px;
    }
  }

  // -------------------------------------------------- EO VR
}

// ----------------------------------- HEADER

.calendar__header {
  padding: 5px 0;
  .calendar__date {
    text-align: center;
  }
  .date__day,
  .date__number {
    display: block;
    font-family: $font-titre;
    line-height: 1;
  }
  .date__day {
    font-size: rem(20);
  }
  .date__number {
    font-size: rem(55);
    color: $color-2;
  }
}

// ----------------------------------- BODY

.calendar__body {
  position: relative;

  .calendar__schedule__date {
    display: none;
  }
  .calendar__link {
    // height: 80px;
    position: relative;
    // display: flex;
    align-items: center;
    transition: all 0.1s;
  }
  .item:nth-child(even) {
    background-color: $gray-2;
  }

  .calendar__title {
    display: flex;
    align-items: center;
    font-family: $font-titre;
    font-size: rem(22);
    padding-left: 25px;
    .movie_version {
      white-space: nowrap;
      background: $color-1;
      padding: 2px 4px;
      border-radius: 3px;
      font-size: rem(11);
      color: white;
      margin-left: rem(6);
      font-family: $font-normal;
    }
  }

  .calendar__schedule {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;
  }

  .schedule__title {
    display: inline-block;
    width: 50px;
    margin: 2px;
    font-family: $font-titre;
    font-size: rem(18);
    background-color: $color-3;
    color: white;
  }

  .item:hover .calendar__link {
    z-index: 10;
    box-shadow: 5px 20px 41px rgba($color: #000000, $alpha: 0.16);

    .calendar__title {
      color: $color-2;
    }

    .schedule__title {
      background-color: $color-2;
    }
  }

  .movie_version {
    font-size: rem(14);
    background-color: $color-2;
    color: white;
    padding: 1px 4px;
  }
}

// ----------------------------------- BORDER

.calendar__border {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .border__item {
    border-right: 4px dotted $gray-5;
    &:last-child {
      border: none;
    }
  }
}

@include breakpoint(1600) {
  .calendar__header {
    .date__day {
      font-size: rem(18);
    }
    .date__number {
      font-size: rem(45);
    }
  }

  .calendar__body {
    .calendar__title {
      font-size: rem(20);
      padding-left: 20px;
    }

    .calendar__schedule {
      padding: 8px 0;
    }

    .schedule__title {
      width: 48px;
      font-size: rem(16);
    }
  }
}

@include breakpoint(1440) {
  .calendar__header {
    .date__day {
      font-size: rem(16);
    }
    .date__number {
      font-size: rem(40);
    }
  }

  .calendar__body {
    .calendar__title {
      font-size: rem(18);
    }
  }
  .programmes.programmes--vr .btn {
    font-size: rem(16);
    padding: 2px 8px;
    border: 1px solid $color-2;
  }
}

@include breakpoint(980) {
  .calendar__header {
    .date__day {
      font-size: rem(16);
    }
    .date__number {
      font-size: rem(36);
    }
  }

  .programmes {
    .calendar__header,
    &.programmes--movies .calendar__link,
    // &.programmes--vr .item,
    .calendar__border {
      display: grid;
      grid-template-columns: 0 repeat(7, minmax(65px, 140px));
    }
  }

  .programmes.programmes--movies .calendar__link div:nth-child(2) {
    grid-column-start: 2;
  }
  .calendar__body {
    .calendar__title {
      position: relative;
      z-index: 10;
      grid-column: 1 / -1;
      background-color: $color-3;
      color: white;
      font-size: rem(26);
      text-align: center;
      padding-left: 15px;
    }

    .schedule__title {
      background-color: transparent;
      color: $color-2;
      border: 2px solid $color-2;
      width: 40px;
    }

    // ---------------------- supprimer les effets au hover sur mobile
    .item:hover .calendar__link {
      z-index: 10;
      box-shadow: none;

      .calendar__title {
        color: white;
      }

      .schedule__title {
        background-color: transparent;
        color: $color-2;
        border: 2px solid $color-2;
      }
    }
  }
  .calendar__border {
    .border__item {
      &:first-child {
        border: none;
      }
    }
  }

  .programmes.programmes--vr .btn {
    font-size: rem(14);
    padding: 1px 6px;
    border: 1px solid $color-2;
  }
  // ---------------------- ajout d'une marge entre les items
  .programmes--movies .item::after {
    content: "";
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    height: 20px;
    margin-top: -3px;
    background-color: white;
    border-top: 2px solid $color-3;
  }
  .programmes--vr .item {
    margin-bottom: rem(17);
    border-bottom: 2px solid $color-3;
  }
}

@include breakpoint(680) {
  .programmes.programmes--movies .calendar__link,
  .programmes.programmes--vr .item {
    display: block;
  }
  .programmes .calendar__border,
  .programmes .calendar__header {
    display: none !important;
  }
  .calendar__body .calendar__schedule {
    display: flex;
    flex-direction: row;
    justify-content: start;
    border-bottom: 2px dotted $gray-4;
  }

  .calendar__body .calendar__title {
    text-align: left;
    padding-left: 15px;
    font-size: rem(22);
  }

  .calendar__body .item:nth-child(even) {
    background-color: white;
  }

  //   --------------------- si cellule vide
  .calendar__body .calendar__schedule--empty {
    display: none;
  }
  .calendar__body .calendar__schedule__date {
    display: inline-flex;
    align-items: center;
    width: 150px;
    padding-left: 15px;
    .date__day,
    .date__number {
      font-family: $font-titre;
      line-height: 1;
      color: $color-3;
    }
    .date__day {
      font-size: rem(20);
      margin-right: 8px;
    }
    .date__number {
      font-size: rem(20);
    }
  }

  .programmes.programmes--vr .btn {
    font-size: rem(16);
    padding: 2px 8px;
    border: 1px solid $color-2;
  }
}

@include breakpoint(440) {
}
