// ------------------------------------------------------------------------------------ blog

.page-blog h1 {
  margin: 2rem 0;
}

.page-blog .blog {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  .items-row {
  }

  .item,
  .item img {
    height: 100%;
  }

  .item {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    grid-gap: 20px;
    overflow: hidden;
    margin-bottom: 3rem;
    @include shadow-1;
    border-radius: 7px;
  }

  h2 {
    color: $color-1;
  }

  .item img {
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .item__body {
    padding: 1rem;

    p {
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 4;
      // overflow: hidden;
    }
  }
}

@include breakpoint(980) {
  .page-blog .blog {
    .item {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    .item {
      height: auto;
    }
    .item img {
      height: 250px;
    }
    .item__body {
      padding: 0 1rem 1rem 1rem;
      p {
        -webkit-line-clamp: 2;
      }
    }
  }
}

// ------------------------------------------------------------------------------------ détails
.page-blog {
  // ------------------------------------- header
  .article__header {
    position: relative;
    margin-bottom: 2rem;

    // ----------------------- titre
    .page-header {
      position: absolute;
      z-index: 10;
      width: 100%;
      text-align: center;
      padding: 0 4rem;
      top: 50%;
      transform: translateY(-50%);
    }

    .page-header h2 {
      color: white;
      font-size: rem(50);
      text-shadow: 0 4px 4px rgba($color: #000000, $alpha: 0.7);
    }

    // ----------------------- image
    .item-image {
      position: relative;
    }

    .item-image::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.2);
    }
    .item-image img {
      display: block;
      width: 100%;
      height: 400px;
      object-fit: cover;
      object-position: center;
    }
  }
  // ------------------------------------- body
  .article__body {
  }
}
