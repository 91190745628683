.movies__details {
  // -------------------------------------------- HEADER
  .details__header {
    margin: 2rem 0;
    .title__h1 {
      margin: 0;
      line-height: 1;
    }

    .details__author {
      text-transform: uppercase;
      font-size: rem(25);
      font-weight: 500;
      color: $color-3;
    }
  }

  // -------------------------------------------- BODY

  .details__body {
    display: grid;
    grid-template-columns: repeat(6, minmax(50px, 1fr));
    grid-template-areas:
      "ba ba ba ba synopsis synopsis"
      "poster poster slide slide slide  slide"
      "com com com com com com";
    column-gap: 30px;
    row-gap: 50px;

    img {
      box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.25);
    }
  }

  .details__video {
    grid-area: ba;
    img {
      width: 100%;
      object-fit: cover;
    }
    // -------------------------------------------- infos
    .video__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .video__infos {
      display: inline-flex;
      align-items: center;
      grid-gap: 15px;

      .video__title {
        font-weight: 500;
        font-size: rem(18);
        color: $color-3;
      }
      .video__result {
        font-size: rem(18);
        color: $color-3;
      }
    }
    .video__category {
      //   display: block;
      border: 1px solid $color-3;
      padding: 2px 8px;
    }
  }

  .details__synopsis {
    grid-area: synopsis;
    p {
      font-size: rem(16);
    }
    .details__actors {
      margin-top: 1rem;
    }
    .details__age {
      margin-top: 1rem;
      background-color: $danger;
      color: white;
      padding: 1rem 2rem;
    }
  }

  .details__poster {
    grid-area: poster;
  }

  .details__slide {
    grid-area: slide;
  }

  .details__com {
    grid-area: com;

    .com__link {
      margin-bottom: 1rem;
    }
  }

  // -------------------------------------------- SLIDE
  .details__slide {
    position: relative;

    .swiper-container {
      height: 524px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: white;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      color: $color-2;
      transform: translateY(-50%);

      &::after {
        font-size: 1.4rem;
        font-weight: bold;
      }
    }
    .swiper-button-prev::after {
      padding-right: 6px;
    }
    .swiper-button-next::after {
      padding-left: 2px;
    }
  }
}

@include breakpoint(1600) {
  .movies__details .details__video .video__infos .video__title,
  .movies__details .details__video .video__infos .video__result {
    font-size: rem(18);
  }
}

@include breakpoint(1440) {
  .movies__details .details__body {
    grid-template-areas:
      "ba ba ba ba ba ba"
      "poster poster  synopsis synopsis synopsis synopsis"
      "slide slide slide slide slide slide"
      "com com com com com com";
    column-gap: 20px;
    row-gap: 30px;
    align-items: center;
  }
  .movies__details .details__slide .swiper-container {
    height: 440px;
  }
  .movies__details .details__video .video__infos .video__title,
  .movies__details .details__video .video__infos .video__result {
    font-size: rem(16);
  }
  .movies__details .details__video .video__infos {
    gap: 10px;
  }
}

@include breakpoint(740) {
  .movies__details .details__body {
    grid-template-areas:
      "ba ba ba ba ba ba"
      "synopsis synopsis synopsis synopsis synopsis synopsis"
      "slide slide slide slide slide slide"
      "poster poster poster poster . ."
      "com com com com com com";
  }
  .movies__details .details__slide .swiper-container {
    height: 340px;
  }
  .movies__details .details__video .video__infos .video__title,
  .movies__details .details__video .video__infos .video__result {
    font-size: rem(14);
  }
  .movies__details .details__video .video__infos {
    gap: 7px;
    flex-wrap: wrap;
  }
}

@include breakpoint(440) {
  .movies__details .details__body {
    grid-template-areas:
      "ba ba ba ba ba ba"
      "synopsis synopsis synopsis synopsis synopsis synopsis"
      "slide slide slide slide slide slide"
      "poster poster poster poster poster poster"
      "com com com com com com";
    column-gap: 0;
  }
  .movies__details .details__slide .swiper-container {
    height: 340px;
  }
}
