body.espace-client__login {
  height: 100vh;
  overflow: hidden;

  .footer {
    border: 0;
  }
}

.espace-client__login {
  position: relative;
  .admin_login {
    max-width: 500px;
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  form {
    padding: 2rem;
    border: 1px solid $gray-3;
  }

  .controls {
    margin-bottom: 1.5rem;
  }

  label {
    display: block;
    color: $color-1;
    text-align: center;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    .star {
      display: none;
    }
  }

  .btn {
    margin-top: 1rem;
  }

  .mdp-id-forget {
    .nav {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      text-align: center;

      li {
        flex: 1 0 100%;
        width: 50%;

        a {
          display: block;
          background-color: $gray-3;
          padding: 0.5rem 0;
          font-size: 0.9rem;
        }
      }
    }
  }
}

/* ------------------------------------------------------------- RESPONSIVE ------------------------------------------------------------- */
