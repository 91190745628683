.acces__wrapper {
  .items {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 375px);
    gap: 40px;
    align-items: flex-start;
  }
}

.acces__infos {
  .card {
    background-color: $color-3;
    height: 500px;
    color: white;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.26);
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .card__infos {
    margin: 1rem 0 0 1rem;
    a {
      margin-bottom: 1rem;
    }
    span {
      font-family: $font-titre;
      font-size: rem(28);
      line-height: 1.3;
    }
  }
}

// -------------------------------------------- MAP
#map {
  width: 100%;
  height: 500px;
  box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.26);
}

/* ======================================================================  RESPONSIVE ============================= */

@include breakpoint(980) {
  .acces__wrapper .items {
    grid-template-columns: minmax(300px, 1fr);
  }
  .acces__infos .card {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
    align-items: center;
    height: initial;
  }
}

@include breakpoint(700) {
  .acces__infos .card {
    // display: block;
    align-items: center;
    height: initial;
    grid-template-columns: minmax(180px, 1fr) minmax(200px, 1fr);
    .contact__link {
      justify-content: flex-start;
    }
  }
  .acces__infos .card__infos span {
    font-size: rem(20);
  }
}

@include breakpoint(440) {
  .acces__infos .card {
    grid-template-columns: 1fr;
    padding-bottom: 2rem;
  }
  .acces__infos .card__infos span {
    font-size: rem(22);
  }
}
