.header__top {
  padding: rem(8) 0;
  display: grid;
  grid-template-columns: 500px 1fr;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-4;

  .left {
    .btn {
      margin-right: rem(8);
    }
  }

  .right {
    form {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .form-group {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      margin: 0;
    }

    label {
      display: inline-flex;
      margin-right: rem(8);
      margin-left: 0;
      font-size: rem(14);
      font-weight: 500;
      text-align: right;
    }

    input {
      max-width: 300px;
      width: 100%;
      height: 34px !important;
    }

    .btn {
      margin-left: rem(8);
      width: auto !important;
    }
  }
}

@include breakpoint(1600) {
  .header__top .right input {
    max-width: 250px;
    padding-left: 10px !important;
  }
  .header__top .right label {
    font-size: rem(12);
  }
}

@include breakpoint(1440) {
  .header__top .right .form-group {
    display: block;
    width: auto;
  }
  .header__top .right {
    align-items: flex-end;
  }
}

@include breakpoint(1220) {
  .header__top .right label {
    font-size: rem(10);
  }
}

@include breakpoint(980) {
  .header__top {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .header__top .right {
    display: none;
  }
}

@include breakpoint(550) {
  .header__top {
    padding: rem(6) 0;
    .left {
      //   display: inline-flex;
      //   justify-content: space-between;
      //   flex-wrap: wrap;
    }
  }
  .header__top .left .btn {
    margin: rem(1);
    font-size: rem(15);
  }
}

@include breakpoint(440) {
  .header__top {
    .left {
      display: inline-flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}
