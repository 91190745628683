@charset 'utf-8';
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,400&display=swap");

@import "utils/functions";
@import "utils/mixins";
@import "utils/variables";
@import "utils/scrollbar";

@import "base/reset";
@import "base/responsive";
@import "base/typography";

@import "components/buttons";
@import "components/form";
@import "components/pagination";
@import "components/modal";
@import "components/message";
@import "components/breadcrumb";
@import "components/slide-all";
@import "components/rgpd";
@import "components/card";
@import "components/date-picker";
@import "components/programmes";
@import "components/details";
@import "components/loader";
@import "components/popup-message";

@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/header-top";

@import "pages/404";
@import "pages/sitemap";
@import "pages/rgpd";
@import "pages/home";
@import "pages/blog";
@import "pages/realite-virtuelle";
@import "pages/tarifs";
@import "pages/acces";
@import "pages/slide-ecran";
@import "pages/page5";
@import "pages/page7";
@import "pages/page8";
@import "pages/contact";

@import "espace-client/espace-client";
@import "espace-client/connexion";
@import "espace-client/actualite";
@import "espace-client/navigation";
@import "espace-client/galerie";
@import "espace-client/abonnement";

@import "vendors/swiper-bundle.scss";
@import "vendors/plyr/plyr.scss";

// ---------------------------------------------- FONTAWESOME
// @import "vendors/fontawesome/fontawesome";
// @import "vendors/fontawesome/solid";
// @import "vendors/fontawesome/regular";
