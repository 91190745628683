/* -------------------------- ESPACE CLIENT - GALERIE */

.wrapper-bloc-image .bloc-image {
  position: relative;
  display: inline-block;
  margin: 2px;
}
.wrapper-bloc-image .content-image {
  overflow: hidden;
}
.wrapper-bloc-image img {
  max-height: 150px;
}
.wrapper-bloc-image .content-image span {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0.5rem;
  font-size: 0.9rem;
  color: #0f2027;
}
.wrapper-bloc-image a {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 30px;
  height: 30px;
}

.wrapper-bloc-image svg {
  width: 30px;
  height: 30px;
}
.wrapper-bloc-image path {
  fill: #fff;
}
