@mixin rotate {
  transform: 10deg;
}

// ----------------------------------- fontawesome
@mixin icon($name) {
  content: $name;
  font-family: var(--font-awe);
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

@mixin arrow($size, $color, $color-hover: blue) {
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: $size;
    height: $size;
    background-color: $color;
    left: calc(-#{$size} / 2);
    top: calc(50% - (#{$size} / 2));
    transform: rotate(45deg);
    border-radius: 2px;
  }
  &:hover::after {
    background-color: $color-hover;
  }
}

// ---------- Gradient

@mixin gradient-1 {
  background: rgb(191, 33, 24);
  background: linear-gradient(
    90deg,
    rgba(191, 33, 24, 1) 0%,
    rgba(191, 24, 24, 1) 25%,
    rgba(196, 62, 37, 1) 60%,
    rgba(227, 76, 45, 1) 85%,
    rgba(227, 49, 45, 1) 100%
  );
}

@mixin gradient-2 {
  background: rgb(9, 23, 25);
  background: linear-gradient(
    90deg,
    rgba(9, 23, 25, 1) 0%,
    rgba(29, 54, 58, 1) 100%
  );
}

// ---------- Shadow

@mixin shadow-1 {
  box-shadow: 0 16px 32px rgba($color: #000000, $alpha: 0.16);
}

@mixin shadow-2 {
  box-shadow: 0 26px 52px rgba($color: #000000, $alpha: 0.26);
}

/*
************* RESPONSIVE
* ===================================
*/

@mixin breakpoint($media) {
  @media screen and (max-width: #{$media}px) {
    @content;
  }
}

// $breakpoints: (
//   "xxs": 550px,
//   "xs": 768px,
//   "s": 980px,
//   "m": 1200px,
//   "l": 1440px,
//   "xl": 1600px,
//   "xxl": 1800px,
// );
// @mixin breakpoint($_key) {
//   @media screen and (max-width: map-get($breakpoints, $_key)) {
//     @content;
//   }
// }

/*
************* VISIBILITY
* ===================================
*/

@mixin hidden {
  position: absolute !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}

@mixin visible {
  position: relative !important;
  border: 0 !important;
  height: auto !important;
  width: auto !important;
  padding: 0 !important;
  overflow: visible !important;
  clip: auto !important;
}
