//  ----------------- icons
.icon-upload::before {
  content: "ajouter une image";
  font-style: normal;
}

.icon-remove::before {
  content: "supprimer";
}

.icon-calendar::before {
  content: "choisir une date";
}

// ------------------------ btn modif frontend
a.btn.jmodedit::before {
  content: "modifier";
}
//  ----------------- mes actus

.espace-client__actu {
  table.category {
    margin-top: 1rem;
    width: 100%;
    text-align: left;
    // ---------- header
    thead {
      border-bottom: 1px solid $gray-4;
      th {
        padding: 10px 0;
      }
    }
    #categorylist_header_title {
      padding-left: 15px;
    }
    #categorylist_header_edit,
    tr td:last-child {
      text-align: center;
    }

    .list-title {
      width: 80%;
      padding-left: 15px;
    }
    .cat-list-row0 {
      background-color: $gray-3;
    }
    td {
      padding: 15px 0;
    }
    // ---------- btn modifier
    .list-edit a {
      background-color: $color-1;
      color: white;
      padding: 2px 8px;
      font-size: rem(14);
      &:hover {
        background-color: rgba($color: $color-1, $alpha: 0.7);
      }
    }
  }

  .btn-toolbar {
    margin-top: 2rem;
  }
}

// -------------------------------- Modification module & article
#module-form,
#adminForm {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 0 1rem;

  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .btn {
    font-size: rem(16);
    padding: 6px 12px;
    margin-right: 0.5rem;
  }
}

// ---------- module
#module-form {
  .page_title,
  #editor-xtd-buttons,
  .control-group,
  #moduleSlide {
    display: none;
  }
}

// ---------- article

.edit-icon a {
  @extend .btn;
}

#adminForm {
  #com-content-formTabs,
  #editor-xtd-buttons,
  #jform_tags_chzn,
  #jform_tags,
  #jform_tags-lbl,
  #jform_note,
  #jform_note-lbl,
  #jform_version_note,
  #jform_version_note-lbl,
  #jform_created_by_alias,
  #jform_created_by_alias-lbl,
  #jform_access_chzn,
  #jform_access-lbl,
  #jform_language_chzn,
  #jform_language-lbl,
  #jform_metadesc,
  #jform_metadesc-lbl,
  #jform_metakey,
  #jform_metakey-lbl,
  #jform_images_image_intro_caption,
  #jform_images_image_intro_caption-lbl,
  #jform_images_float_intro_chzn,
  #jform_images_float_intro-lbl,
  #jform_images_image_fulltext_caption,
  #jform_images_float_fulltext_chzn,
  #jform_images_float_fulltext-lbl,
  #jform_images_image_fulltext_caption-lbl,
  #jform_urls_urla,
  #jform_urls_urlb,
  #jform_urls_urlc,
  #jform_urls_urla-lbl,
  #jform_urls_urlb-lbl,
  #jform_urls_urlc-lbl,
  #jform_urls_urlatext,
  #jform_urls_urlbtext,
  #jform_urls_urlctext,
  #jform_urls_urlatext-lbl,
  #jform_urls_urlbtext-lbl,
  #jform_urls_urlctext-lbl,
  #jform_catid_chzn,
  #jform_catid-lbl,
  #jform_articletext_pagebreak {
    display: none;
  }
}

/* ------------------- BOUTON AJOUTER / MODIF ARTICLE / CATEGORIE ------------------- */

.links_btn_submit {
  margin-top: 1rem;
  input.btn,
  .btn {
    width: auto;
    margin: 0;
    height: initial;
  }
  input.btn {
    min-width: 250px;
    margin-right: 1.5rem;
  }
}
