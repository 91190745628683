/*
************* COLORS
* ==================
*/
$color-1: white;

$color-1: #0f2027;
$color-2: #e7393c;
$color-3: #2c5364;
$color-4: #203a43;

$color-light: #ffffff;
$color-dark: #111111;

$gray-1: #f9f9f9;
$gray-2: #f5f5f5;
$gray-3: #f1f1f1;
$gray-4: #eaeaea;
$gray-5: #bebebe;

$danger: #e7393c;
$validation: #50c35f;

/*
************* Z-INDEX
* ===================================
*/

$z0: 1;
$z1: 10;
$z2: 20;
$z3: 30;
$z4: 40;
$z5: 50;
$z6: 60;
$z7: 70;
$z8: 80;
$z9: 90;
$z10: 100;
$z11: 101;

/*
************* FONT-FAMILY
* ===================================
*/

$font-normal: "Roboto", sans-serif;
$font-titre: "Bebas Neue", cursive;
$font-awe: "Font Awesome 5 Free";

.color-1 {
  color: $color-1;
}

.color-2 {
  color: $color-2;
}

/*
************* FONT-SIZE
* ===================================
*/

$font-size-base: 16px !default;

/*
************* TIPS
* ===================================
*/

.nowrap {
  white-space: nowrap;
}
