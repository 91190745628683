.header {
  position: fixed;
  width: 330px;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: $color-1;
}

.header__container {
}

// ------------------------ Logo
.header__logo {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

// ------------------------ Sociaux

.header__reseaux {
  position: absolute;
  bottom: 1rem;
  margin-left: 2rem;
  .reseaux__title {
    display: block;
    font-family: $font-titre;
    font-size: rem(20);
    margin-bottom: rem(5);
  }

  span,
  i {
    color: white;
  }
}

.header__reseaux {
  i {
    font-size: rem(35);
    margin-right: rem(5);
  }
}

.header__reseaux--mobile {
  z-index: -1;
  opacity: 0;
  transform: translateY(-10px);
}

// ------------------------ Newsletter

.form-group--newsletter {
  display: none;
}

// -------------------------------------------------------------------------------- MENU

.menu__main {
  // display: flex;
  // align-items: center;
  // justify-content: center;

  margin: 0 rem(12);

  //------------------------------------------------------ li
  .menu li {
    margin: 1rem 0;
  }

  //------------------------------------------------------ a
  .menu a {
    display: flex;
    border-radius: 10px;
    align-items: center;
    font-family: $font-titre;
    font-size: rem(24);
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    padding: 7px 12px;
    border-radius: 10px;
  }

  a,
  span,
  path {
    transition: background 0.25s, fill 0.25s;
  }

  svg {
    margin-right: rem(22);
  }

  .menu li.active {
    a {
      color: white;
      background-color: $color-2;
    }
    svg,
    path {
      fill: white;
    }
  }
  //------------------------------------------------------ hover
  .menu a:hover {
    color: white;
    background-color: $color-2;
    svg,
    path {
      fill: white;
    }
  }
}

//------------------------------------------------------ bouton menu
#menu__btn {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  cursor: pointer;
  z-index: 9999;
  outline: 0;
  background-color: transparent;
  border: 0;
  .menu__mobile__title {
    display: inline-flex;
    text-transform: uppercase;
    color: white;
    line-height: 0;
    font-family: $font-titre;
    font-size: rem(20);
    margin-top: rem(10);
    letter-spacing: rem(1.3);
  }
}

.menu__bar {
  position: relative;
  display: block;
  width: 40px;
  height: 3px;
  background-color: white;
  margin-bottom: 7px;
  border-radius: 2px;
  transition: all 150ms;
}

#menu__btn.menu__btn--active {
  // position: relative;
  .menu__bar--1 {
    opacity: 0;
  }
  .menu__bar--2 {
    transform: rotate(45deg);
  }
  .menu__bar--3 {
    transform: rotate(-45deg);
    top: -10px;
  }
}

//------------------------------------------------------ RESPONSIVE

@include breakpoint(1700) {
  .header__logo {
    padding: 2rem 1rem 1rem 1rem;
  }
  .header {
    width: 275px;
  }
  .menu__main {
    margin: 0 8px;
  }
  .menu__main .menu li {
    margin: rem(10) 0;
  }
  .menu__main .menu a {
    padding: 5px 12px;
    font-size: rem(20);
  }
  .menu__main svg {
    width: 28px;
    margin-right: rem(12);
  }
  .header__reseaux {
    bottom: rem(8);
  }
  .header__reseaux .reseaux__title {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}

@include breakpoint(1440) {
  .header__logo {
    padding: 1.5rem 1rem 1rem 1rem;
  }
  .header {
    width: 250px;
  }
  .menu__main {
    margin: 0 8px;
  }
  .menu__main .menu li {
    margin: rem(6) 0;
  }
  .menu__main .menu a {
    padding: 3px 12px;
    font-size: rem(18);
  }
  .menu__main svg {
    width: 25px;
    margin-right: rem(10);
  }
  .header__reseaux {
    bottom: rem(8);
  }
  .header__reseaux .reseaux__title {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}

@include breakpoint(1180) {
  .header {
    position: relative;
    width: 100%;
    max-height: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .header__logo {
    position: absolute;
    left: 1rem;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .header__logo img {
    display: block;
    position: relative;
    height: 80px;
  }
  #menu__btn {
    display: inline-block;
  }
  .menu__main {
    position: fixed;
    // display: flex;
    // align-items: center;
    top: 0;
    right: -300px;
    margin: 0;
    // flex-direction: column;
    // justify-content: center;
    // align-items: stretch;
    // width: 300px;
    height: 100vh;
    background-color: $color-1;
    transition: all 200ms;
    z-index: -1;
    .menu {
      // display: flex;
      // flex-direction: column;
      // // align-items: center;
      // justify-content: center;
      margin: 110px 1rem 0 1rem;
    }
  }

  // -------------------------- menu active
  .header__menu--active .menu__main {
    right: 0;
    z-index: 9998;
  }
  .header__reseaux {
    display: none;
  }
  .header__reseaux.header__reseaux--mobile {
    display: block;
    transition: 0.2s;
    top: 1rem;
    bottom: initial;
    margin-left: 1rem;
  }
  .header__reseaux--mobile i {
    font-size: rem(32);
  }
  .header__reseaux--mobile ul {
    display: flex;
    li {
      margin-right: rem(4);
    }
  }
  .header__menu--active .header__reseaux--mobile {
    transition-delay: 0.2s;
    transform: translateY(0);
    opacity: 1;
    z-index: 9998;
  }
  // ------------------------ Newsletter
  .form-group--newsletter {
    display: block;
  }
}

@include breakpoint(1000) {
}
