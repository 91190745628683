#preloader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  margin-left: 330px;
  right: 0;
  bottom: 0;
  background-color: $color-1;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: $color-3;
  z-index: 999;
}
.page-101 #preloader {
  display: block;
}
#status {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  background: white;
  animation: flipX 1s linear infinite;
}

@keyframes flipX {
  0% {
    transform: perspective(200px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(200px) rotateX(-180deg) rotateY(0deg);
  }
  100% {
    transform: perspective(200px) rotateX(-180deg) rotateY(-180deg);
  }
}

@include breakpoint(1700) {
  #preloader {
    margin-left: 275px;
  }
}

@include breakpoint(1440) {
  #preloader {
    margin-left: 250px;
  }
}

@include breakpoint(1180) {
  #preloader {
    margin-top: 0;
    margin-left: 0;
    border-width: 1px 0 0 0;
  }
}

@include breakpoint(550) {
  //   #preloader {
  //     margin: 0;
  //   }
}
