.modal {
  position: fixed;
  opacity: 0;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: $color-dark, $alpha: 0.99);
}

.modal__wrapper {
  position: absolute;
  width: 1180px;
  max-width: 90%;
  // max-height: 50vh;
  // overflow: auto;
  top: 50%;
  left: 50%;
  transform: scale(1, 0) translate(-50%, -50%);
  background-color: $color-light;
  @include shadow-2;
  transition: all 300ms;

  .form-group {
    margin-bottom: 1rem;
  }
}

.modal__close {
  --height: 50px;
  position: absolute;
  z-index: 10;
  color: $color-2;
  top: calc(var(--height) * -1);
  height: var(--height);
  right: 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: rem(50);
  font-weight: 500;
  cursor: pointer;
  svg {
    margin-left: rem(6);
  }
}

//------------------------------------- MODAL ACTIVE
.body__modal--active {
  height: 100vh;
  overflow: hidden;
}

.modal--active {
  opacity: 1;
  z-index: 10000;
  transition: all 200ms;

  .modal__wrapper {
    opacity: 1;
    transform: scale(1, 1) translate(-50%, -50%);
  }
}
