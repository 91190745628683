#tarteaucitronAlertBig {
  width: 250px !important;
  left: 1rem !important;
  bottom: 1rem !important;
  padding: 1rem !important;
  background: white !important;
  box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.25);
}

// ---------------------- btn

#tarteaucitronPersonalize {
  margin: 1rem 0 !important;
}
#tarteaucitronPercentage {
  display: none !important;
}
#tarteaucitronDisclaimerAlert {
  font-family: var(--font-normal) !important;

  b {
    color: $color-1 !important;
  }
}

// #tarteaucitronPercentage,
// #tarteaucitronPersonalize {
//   background-color: $color-1 !important;
// }

// #tarteaucitronDisclaimerAlert {
//   color: $color-1 !important;
//   font-family: var(--font-normal) !important;
// }
