.title__picker {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  background-color: white;
  padding-right: 1rem;
}

.date__picker,
.date__week {
  display: flex;
  align-items: center;
}

.date__week {
  &.date__week--visible {
    display: flex;
  }
  &.date__week--hidden {
    display: none;
  }
}

.date__buttons {
  margin: 0 1rem 0 2rem;
}

.date__week__title {
  color: $color-1;
  font-family: $font-titre;
  font-size: rem(22);
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: $color-2;
  }
}

.date__prevnext {
  margin-left: 1rem;
  i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    color: white;
    text-align: center;
    // font-weight: 400;
    font-size: rem(30);
    background-color: $color-2;
  }
}
.date__prevnext i:hover {
  background-color: $color-1;
}

// --------------------------------------------------------- RESPONSIVE

@include breakpoint(1250) {
  .date__week__title {
    font-size: rem(18);
  }
}

@include breakpoint(550) {
  .date__picker {
    display: block;
  }
  .date__week {
    margin-top: 1rem;
  }
  .title__picker .date__week {
    margin-top: 0;
  }
  .date__prevnext i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: rem(22);
  }
}

@include breakpoint(440) {
  .title__picker {
    display: block;
  }
  .title__picker::after {
    content: none;
  }
  .title__picker {
    background-color: transparent;
  }
  .title__picker .title {
    background-color: transparent !important;
  }
}
