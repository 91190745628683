.slide__all {
  position: relative;
  height: 400px;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0;
    background-color: rgba($color: $color-1, $alpha: 0.4);
  }

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
}

.slide__title {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  // font-weight: medium;
  font-size: rem(55);
  text-transform: uppercase;
}

@include breakpoint(1200) {
  .slide__all {
    height: 300px;
  }
  .slide__title {
    font-size: rem(35);
  }
}

@include breakpoint(550) {
  .slide__all {
    height: 200px;
  }
  .slide__title {
    font-size: rem(25);
  }
}
