::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $color-1;
}

::-webkit-scrollbar-thumb {
  background: $color-2;
  border: 2px solid $color-1;
  border-radius: 7px;
}
